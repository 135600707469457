import React from 'react'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'

import Grow from '@material-ui/core/Grow'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingTop: '150px',
        paddingBottom: '50px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '100px',
            paddingBottom: '20px',
        },
    },
    root: {
        [theme.breakpoints.up('md')]: {
            '& .MuiTabs-flexContainer': {
                width: '45%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '10px',
                border: '1px solid #0f051821',
                borderRadius: '23px',
            },
        },
    },
    imageBox: {
        width: '100px',
        marginLeft: '16px',
        marginTop: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '52px',
        },
    },
    label: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
        fontSize: '18px',
        color: '#F1571E',
        textTransform: 'none',
    },
    title: {
        fontSize: '15px',
        minHeight: '54px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '20px',
            fontSize: '14px',
        },
    },
    text: {
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    header: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: '#75CCBD',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem',
        },
    },
    generalTitle: {
        fontSize: '3.375rem',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem',
        },
    },
    tab: {
        minHeight: '215px',
    },
    orangeLeft: {
        position: 'absolute',
        left: '15%',
        top: '-80px',
        [theme.breakpoints.down('sm')]: {
            width: '31px',
            height: '31px',
        },
    },
    orangeRight: {
        position: 'absolute',
        right: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '13px',
            height: '13px',
            top: '-80px',
            right: '5%',
        },
    },
    greenLeft: {
        position: 'absolute',
        left: '7%',
        top: '150px',
        [theme.breakpoints.down('sm')]: {
            width: '15px',
            height: '15px',
            top: '50px',
        },
    },
    greenRight: {
        position: 'absolute',
        top: '150px',
        right: '10%',
        [theme.breakpoints.down('sm')]: {
            width: '20px',
            height: '20px',
            top: '828px',
            right: '0px',
        },
    },
    grayLeft: {
        position: 'absolute',
        top: '250px',
        right: '0px',
        [theme.breakpoints.down('sm')]: {
            width: '16px',
            height: '25px',
            top: '250px',
        },
    },
    card: {
        minHeight: '370px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '350px',
        },
    },
    link: {
        height: '100%',
        fontSize: '1.25rem',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: theme.palette.secondary.black,
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.text.black,
        },
    },
}))

const link = (name, pathPrefix) =>
    name === 'index' ? '/' : pathPrefix ? `/${pathPrefix}/${name}` : `/${name}`

function TabPanel(props) {
    const { title, children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ marginTop: '5px' }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const ContentComponent = (props) => {
    const { item, data } = props
    const tabContent = item.content
    const classes = useStyles()

    return tabContent.map((item) => {
        const images = getImage(item.image)
        const hasLink = Boolean(item.link && item.link.name)

        return (
            <Grid item xs={6} md={4} lg={2}>
                <Card className={classes.card}>
                    <CardActionArea disableRipple>
                        <Link
                            to={
                                hasLink &&
                                link(item.link.name, item.link.pathPrefix)
                            }
                            className={classes.link}
                        >
                            <Box className={classes.imageBox}>
                                <GatsbyImage
                                    className={classes.image}
                                    image={images}
                                    alt={item.image.title}
                                />
                            </Box>
                            <CardContent>
                                <Typography
                                    style={{ fontWeight: 'bold' }}
                                    className={classes.title}
                                >
                                    {item.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {item.text}
                                </Typography>
                            </CardContent>
                        </Link>
                    </CardActionArea>
                </Card>
            </Grid>
        )
    })
}

const ComponentTabs = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles()

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const haveTitle = Boolean(data.title)
    const haveHeader = Boolean(data.header)

    return (
        <Box className={classes.wrapper} pt={14} pb={7}>
            <Grid container style={{ position: 'relative' }}>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/orange_pointy_left.png"
                        alt="orange point left"
                        className={classes.orangeLeft}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/orange_pointy_right.png"
                        alt="orange point right"
                        className={classes.orangeRight}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/green_pointy_left.png"
                        alt="green point left"
                        className={classes.greenLeft}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/green_pointy_right.png"
                        alt="green point right"
                        className={classes.greenRight}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StaticImage
                        src="../../assets/background_icons/gray_pointy_left.png"
                        alt="gray point left"
                        className={classes.grayLeft}
                    />
                </Grid>
            </Grid>
            <Container maxWidth="lg">
                {haveHeader && (
                    <Box className={classes.header} textAlign="center">
                        {data.header}
                    </Box>
                )}
                {haveTitle && (
                    <Box
                        className={classes.generalTitle}
                        textAlign="center"
                        pb={6}
                    >
                        {data.title}
                    </Box>
                )}
                <Box sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    >
                        {data.content.length > 1 ? (
                            <Grow in={true}>
                                <Tabs
                                    TabIndicatorProps={{
                                        style: {
                                            height: '50px',
                                            background:
                                                'linear-gradient(138.25deg, rgba(255, 175, 117, 0.1) 7%, rgba(244, 95, 0, 0.1) 95.28%)',
                                            border: '1px solid rgba(241, 87, 30, 0.05)',
                                            borderRadius: '25px',
                                        },
                                    }}
                                    className={classes.root}
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                >
                                    {data &&
                                        data.content &&
                                        data.content.map((item, index) => {
                                            let newIndex = index + 1
                                            return (
                                                <Tab
                                                    style={{ minWidth: '50%' }}
                                                    label={
                                                        <span
                                                            className={
                                                                classes.label
                                                            }
                                                        >
                                                            {item.title}
                                                        </span>
                                                    }
                                                    {...a11yProps(newIndex)}
                                                />
                                            )
                                        })}
                                </Tabs>
                            </Grow>
                        ) : (
                            ''
                        )}
                        {data.content &&
                            data.content.map((item, index) => {
                                return (
                                    <TabPanel index={index} value={value}>
                                        <Grid container spacing={1}>
                                            <ContentComponent
                                                item={item}
                                                data={data}
                                            />
                                        </Grid>
                                    </TabPanel>
                                )
                            })}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default ComponentTabs
